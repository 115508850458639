import React, { Component } from "react"
import { graphql } from "gatsby"
import { Box } from "@mui/material"

import Header from "../../components/Header"
import NavBar from "../../components/Navbar/Navbar"
import Title from "../../components/Title"
import Footer from "../../components/Footer"
import Blogs from "../../components/Blogs/Blogs.js"

class Homepage extends Component {
  render() {
    const data = this.props.data
    return (
      <Box display="flex" flexDirection="column" flexWrap="wrap">
        <Header />
        <NavBar />
        <Title title="SOME WORD FOR BLOG" subTitle="Our Blogs" />
        <Blogs blogs={data.allWordpressPost.edges} />
        <Footer />
      </Box>
    )
  }
}

export default Homepage

export const pageQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          id
          title
          excerpt
          slug
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          title
          excerpt
          slug
          content
          date(fromNow: true)
          author {
            id
            slug
            name
            url
            description
          }
        }
      }
    }
  }
`
